// axiosInstance.js

import axios from 'axios';

const AxiosInstance = axios.create({
  //baseURL: 'https://billionsoffice.com:3002/', // Reemplaza con tu base URL
  baseURL: 'https://billion-v2-backend-production.up.railway.app/',
});

export default AxiosInstance;
